<template>
  <el-dialog
      :title="'设置项目属性' "
      :close-on-click-modal="false"
      :visible.sync="visible">
    <el-form :model="dataForm" ref="dataForm" @keyup.enter.native="dataFormSubmit()" label-width="80px">
      <el-row  scope="scope">
        <el-form-item label="添加字段">
          <div
              v-for="(col,index) in dataForm.col"
              :key="col.index"
          >
            <el-input :disabled="col.id<1"
                      style="width: 30%; margin-bottom: 5px"
                      v-model="col.remark"
                      placeholder="请输入中文名称"
            ></el-input>
            <el-input :disabled="col.id<1"
                      style="width: 30%; margin-bottom: 5px"
                      v-model="col.name"
                      placeholder="请输入英文名称"
            ></el-input>
            <el-select :disabled="col.id<1"
                       style="width: 25%; margin-bottom: 5px"
                       v-model="col.type"
                       placeholder="请选择属性类型"
            >
              <el-option v-for="item in options"
                         :key="item.value"
                         :label="item.value"
                         :value="item.value">
              </el-option>
            </el-select>
            <el-button :disabled="col.id<1"
                       type="danger"
                       size="small"
                       @click="deleteCol(index)"
            >-
            </el-button>
          </div>
          <el-button v-if="!this.show"
                     type="primary"
                     size="small"
                     @click="addCol()"
                     round
          >+
          </el-button>
        </el-form-item>
      </el-row>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取消</el-button>
      <el-button type="primary" @click="dataFormSubmit()" :disabled="this.show">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  name: "project-attribute-add",
  data () {
    return {
      show: false,
      visible: false,
      dataForm: {
        col: [
          {id:0, name:'id',type:'bigint',remark:'id' },
          {id:0, name:'name',type:'varchar',remark:'名称'},
          {id:0, name :'create_time',type:'date',remark: '创建时间'},
          {id:0, name:'create_user',type:'bigint',remark: '创建者id'},
          // {id:0,name:'hbaseTableName',type: 'varchar',remark: 'hbase表名称'}
        ],
      },
      dataList: [],
      options:[
        {value:'varchar'},
        {value:'bigint'},
        {value:'bool'},
        {value:'date'},
      ],
      rules: {
        remark:[],
        name:[],
        type:[],
      }
    }
  },

  created() {
    // this.currOperator = JSON.parse(
    //     window.sessionStorage.getItem("user")
    // ).userId;
    // this.currOperatorName = JSON.parse(
    //     window.sessionStorage.getItem("user")
    // ).username;
  },

  methods: {
    updateColOptions(id, value)
    {
      for (let i = 0; i < this.dataForm.length; i++) {
        if (id === this.dataForm[i].id) {
          this.dataForm[i].name = value;
          break;
        }
      }
    },
    addCol(){
      this.dataForm.col.push({});
    },
    deleteCol(index){
      this.dataForm.col.splice(index,1);
    },
    init () {
      this.visible = true
      this.dataListLoading = true
      this.reset()
    },
    reset(){
      this.dataForm.col=[
        {id:0, name:'id',type:'bigint',remark:'id' },
        {id:0, name:'name',type:'varchar',remark:'名称'},
        {id:0, name :'create_time',type:'date',remark: '创建时间'},
        {id:0, name:'create_user',type:'bigint',remark: '创建者id'},
        // {id:0,name:'hbaseTableName',type: 'varchar',remark: 'hbase表名称'}
      ];
      this.dataForm.userId=this.currOperator;
    },
    // 表单提交
    dataFormSubmit () {
      this.$confirm(`提交后项目属性将无法添加或修改，确认提交？`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$refs['dataForm'].validate((valid) => {
          if (valid) {
            console.log(this.dataForm)
            this.$http({
              url: this.$http.adornUrl(`/levelManage/projectattributes/createTable`),
              method: 'post',
              data: this.dataForm
            }).then(({data}) => {
              if (data && data.code === 0) {
                this.$message({
                  message: '操作成功',
                  type: 'success',
                  duration: 1500,
                  onClose: () => {
                    this.visible = false
                    this.$emit('refreshDataList')
                  }
                })
              } else {
                this.$message.error(data.msg)
              }
            })
          }
        })
      })
    }
  }
}
</script>

<style scoped>

</style>